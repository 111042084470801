import * as Yup from "yup";

export const AddUserSchema = Yup.object().shape({
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is equired"),
  // address1: Yup.string().required("Address 1 is required"),
  country: Yup.string().required("Country is required"),
  city: Yup.string().required("City is required"),
  zippostal: Yup.string().required("Zip postal is required"),
  mobileNumber: Yup.string().required("Mobile number is required"),
  gender: Yup.string().required("Required"),
  dob: Yup.string().required("Required"),
  birthCountry: Yup.string().required("Required"),
  state: Yup.string().required("State is required"),
  nationality: Yup.string().required("Required"),
  // riskRating: Yup.string().required("Required"),
  idType: Yup.string().required("Required"),
  idNumber: Yup.string().required("Required"),
  idIssueDate: Yup.string().required("Required"),
  idExpiryDate: Yup.string().required("Required"),
  idIssuingAuthority: Yup.string().required("Required"),
  issuingCountry: Yup.string().required("Required"),
  birthState: Yup.string().required("Birth state is required"),
  birthCity: Yup.string().required("Birth city is required"),
  email: Yup.string().required("Email is required"),
  location: Yup.string().required("Location is required"),
});

export const AddCustomerSchema = Yup.object().shape({
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is equired"),
  // email: Yup.string().email("Email is required").required("Required"),
  address1: Yup.string().required("Address 1 is required"),
  country: Yup.string().required("Country is required"),
  city: Yup.string().required("City is required"),
  zippostal: Yup.string().required("Zip postal is required"),
  mobileNumber: Yup.string().required("Mobile number is required"),
  // gender: Yup.string().required("Required"),
  // dob: Yup.string().required("Required"),
  // birthCountry: Yup.string().required("Required"),
  // nationality: Yup.string().required("Required"),
  // riskRating: Yup.string().required("Required"),
  // idType: Yup.string().required("Required"),
  // idNumber: Yup.string().required("Required"),
  // idIssueDate: Yup.string().required("Required"),
  // idExpiryDate: Yup.string().required("Required"),
  // idIssuingAuthority: Yup.string().required("Required"),
  // issuingCountry: Yup.string().required("Required"),
});

export const AddBeneficiarySchema = Yup.object().shape({
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is equired"),
  // email: Yup.string().email("Email is required").required("Required"),
  address1: Yup.string().required("Address 1 is required"),
  country: Yup.string().required("Country is required"),
  mobile_number: Yup.string().required("Mobile number is required"),
});
