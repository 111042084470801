import { createSlice } from "@reduxjs/toolkit";

export const agentsSlice = createSlice({
  name: "agent",
  initialState: {
    agents: [],
    pending: false,
    error: false,
    errorMsg: "",
    transactions: [],
    transactionPending: false,
    transactionError: false,
    transactionErrorMsg: ""
  },

  reducers: {
    handleAgentRequest: (state) => {
      state.pending = true;
    },
    getAgentSuccess: (state, action) => {
      state.pending = false;
      state.error = false;
      state.agents = action.payload;
      state.errorMsg = "";
    },
    createAgentSuccess: (state, action) => {
      state.pending = false;
      state.error = false;

      state.errorMsg = "";
    },
    handleAgentError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
    updateAgentState: (state, action) => {
      state.agents.result = state.agents.result.filter(item => item.id !== action.payload.id);
    },

    handleAgentTransactionRequest: (state) => {
      state.transactionPending = true;
    },
    getAgentTransactionSuccess: (state, action) => {
      state.transactionPending = false;
      state.transactionError = false;
      state.transactions = action.payload;
      state.transactionError = "";
    },
    handleAgentTransactionError: (state, action) => {
      state.transactionError = true;
      state.transactionPending = false;
      state.transactionErrorMsg = action.payload;
    },
  },
});

export const {
  handleAgentRequest,
  getAgentSuccess,
  handleAgentError,
  createAgentSuccess,
  updateAgentState,

  handleAgentTransactionRequest,
  getAgentTransactionSuccess,
  handleAgentTransactionError
} = agentsSlice.actions;
export default agentsSlice.reducer;
