import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./Slices/Auth";
import customerReducer from "./Slices/customerSlice";
import beneficiaryReducer from "./Slices/beneficiarySlice";
import exchangeRateReducer from "./Slices/exchangeRateSlice";
import locationReducer from "./Slices/locationSlice";
import serviceFeeReducer from "./Slices/serviceFeeSlice";
import transactionReducer from "./Slices/transactionSlice";

import userPermissionsReducer from "./Slices/UserPermissions";
import dynamicTabSlice from "./Slices/dynamicTabSlice";
import banksSlice from "./Slices/banksSlice";
import countriesSlice from "./Slices/countriesSlice";
import agentsSlice from "./Slices/agentsSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    customer: customerReducer,
    beneficiary: beneficiaryReducer,
    exchangeRate: exchangeRateReducer,
    location: locationReducer,
    serviceFee: serviceFeeReducer,
    transaction: transactionReducer,
    userPermissions: userPermissionsReducer,
    dynamicTab: dynamicTabSlice,
    banks: banksSlice,
    countries: countriesSlice,
    agent: agentsSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
